import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import dateFormat from 'dateformat';

//api
import api from '../../services/api';

//components
import {Layout, Pagination} from '../../components/Layout';
import swal from "sweetalert";


export default function List(props){

    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        
        async function getList(){
            let params = new URLSearchParams(props.location.search);
            params.append('pagination',true);
            params.append('limit',15);

            let list = await api.get('/form-odonto?'+params);

            setList(list.data.docs);
            setPagination(list.data);
        }
        getList();


    }, [props]);

    async function onClickDelete(id) {
        const willDelete = await swal({
            title: "Deseja realmente excluir?",
            text: "O item será excluido de forma permanente",
            icon: "warning",
            buttons: ["Cancelar", "Excluir"],
            dangerMode: true,
        });

        if (willDelete) {
            await swal("Excluido!", "Item excluido com sucesso!", "success");
            const del = await api.delete('form-odonto/' + id);

            console.log(del)
            if (del.data.status === 'success') {
                window.location.reload();
            }

        }
    }


    return (
        <Layout>

            <div className="filters">
                <h2>Super Odonto</h2>
            </div>
            <hr />

            <Table className="tableList" hover>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Mensagem</th>
                        <th>Data</th>
                        <th>Remover</th>
                    </tr>
                </thead>

                <tbody>
                    {list.map(item => (
                        <tr>
                            <td>{item.nome}</td>
                            <td>{item.email}</td>
                            <td>{item.telefone}</td>
                            <td>{item.mensagem}</td>
                            <td>{dateFormat(item.createdAt, "dd/mm/yyyy H:MM:ss")}</td>
                            <td>
                                <button type={'button'} className={'btn btn-danger'} onClick={()=> onClickDelete(item._id)}>Remover</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination pagination={pagination} />
        </Layout>
    );

};